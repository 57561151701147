import { useCallback, useMemo, MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import Spinner from 'src/_shared/components/Spinner'
import TransientFooter from 'src/_shared/components/TransientFooter'
import InformationCircleIcon from 'src/_shared/components/_icons/InformationCircleIcon'
import AppleAppStoreBadgeImage from 'src/_shared/components/_images/AppleAppStoreBadgeImage'
import GooglePlayBadgeImage from 'src/_shared/components/_images/GooglePlayBadgeImage'
import {
	LANDING_APP_STORE_LINK,
	LANDING_GOOGLE_PLAY_LINK,
	LANDING_FALLBACK_LINK,
	STRAPI_URL
} from 'src/_shared/constants/env'
import useCheckInCpos from 'src/_shared/hooks/useCheckInCpos'
import { useStrapiContext } from 'src/_shared/hooks/useStrapiContext'

const KinetaLandingView = (): JSX.Element | null => {
	const navigate = useRouterNavigate()

	const { cpos, isCpoDataLoading } = useCheckInCpos()

	const { brandData } = useStrapiContext()

	const { logoLight } = brandData?.attributes ?? {}

	const logoUrl = useMemo((): string | null => {
		if (STRAPI_URL) {
			return STRAPI_URL + logoLight?.data?.attributes.url
		}
		return null
	}, [logoLight])

	const handleContinueClick = useCallback(
		(event: MouseEvent<HTMLAnchorElement>): void => {
			// Prevent default anchor link redirection behaviour.
			event.preventDefault()

			const cpoEntityCodes = cpos.map(({ entityCode }): string => entityCode).join(',')
			navigate({
				pathname: ScreenRoutePath.CheckInQrScanner,
				search: `cpoEntityCodes=${cpoEntityCodes}`
			})
		},
		[cpos, navigate]
	)

	return (
		<div className="flex min-h-screen flex-col items-center justify-around bg-grayscale-900 px-5 py-6">
			<header className="pb-4">
				{logoUrl && (
					<div className="min-h-[58px] max-w-72">
						<img alt="Kineta" src={logoUrl} />
					</div>
				)}
			</header>
			<main className="space-y-5 py-4">
				{(LANDING_APP_STORE_LINK || LANDING_GOOGLE_PLAY_LINK) && (
					<div>
						<p className="body-2-normal text-center text-grayscale-100">
							Click Below to Download App
						</p>
					</div>
				)}
				<div className="space-y-5">
					{/* App Store */}
					{LANDING_APP_STORE_LINK && (
						<a
							className="block"
							data-testid="ls-klv-link-app-store"
							href={LANDING_APP_STORE_LINK}
							target="_blank"
							rel="noreferrer"
						>
							<AppleAppStoreBadgeImage className="h-14 w-[194px]" />
						</a>
					)}
					{/* Google Play */}
					{LANDING_GOOGLE_PLAY_LINK && (
						<a
							className="block"
							data-testid="ls-klv-link-google-play"
							href={LANDING_GOOGLE_PLAY_LINK}
							target="_blank"
							rel="noreferrer"
						>
							<GooglePlayBadgeImage className="h-14 w-[194px]" />
						</a>
					)}
					{/* Kineta Charge App User Guide */}
					{LANDING_FALLBACK_LINK && (
						<a
							className="block no-underline"
							data-testid="ls-klv-link-fallback"
							href={LANDING_FALLBACK_LINK}
							target="_blank"
							rel="noreferrer"
						>
							<div className="flex h-14 w-[194px] items-center rounded-lg border border-[#A6A6A6]">
								<InformationCircleIcon className="mx-2.5 h-10 text-grayscale-100" />
								<div>
									<p className="body-2-semibold text-grayscale-100 no-underline">KINETA Charge</p>
									<p className="body-1-light text-grayscale-100 no-underline">App User Guide</p>
								</div>
							</div>
						</a>
					)}
					{/* Guest Mode */}
					{isCpoDataLoading ? (
						<div className="flex h-14 w-[194px] cursor-not-allowed items-center justify-center rounded-lg border border-[#A6A6A6]/50">
							<Spinner className="!h-7 !w-7 !text-grayscale-100/50" />
						</div>
					) : (
						<Link
							className="block no-underline"
							data-testid="ls-klv-link-continue"
							to={ScreenRoutePath.CheckInQrScanner}
							onClick={handleContinueClick}
						>
							<div className="flex h-14 w-[194px] items-center justify-center rounded-lg border border-[#A6A6A6]">
								<p className="body-2-semibold text-grayscale-100 no-underline">Continue As Guest</p>
							</div>
						</Link>
					)}
				</div>
			</main>
			<footer className="pt-4">
				<TransientFooter className="mb-3" variant="dark" />
				{LANDING_APP_STORE_LINK && (
					<p className="caption-2-normal text-center text-typography-tertiary">
						Apple logo® and App Store® are trademarks of Apple Inc.
					</p>
				)}
				{LANDING_GOOGLE_PLAY_LINK && (
					<p className="caption-2-normal text-center text-typography-tertiary">
						Google Play and the Google Play logo are trademarks of Google LLC.
					</p>
				)}
			</footer>
		</div>
	)
}

export default KinetaLandingView
