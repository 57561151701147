import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import VoltalityLogoIcon from 'src/_shared/components/_icons/VoltalityLogoIcon'
import PlugitLogoImage from 'src/_shared/components/_images/PlugitLogoImage'
import { BRAND } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'
import { classNames } from 'src/_shared/utils/elements'

interface TransientFooterProps {
	className?: string
	variant?: 'dark' | 'light'
}

const TransientFooter = ({ className, variant = 'light' }: TransientFooterProps): JSX.Element => {
	const isDarkVariant = variant === 'dark'

	const hidePlugitLogo = [Brand.SpGroup].includes(BRAND)

	return (
		<div className={className}>
			<div className="mb-1">
				<p
					className={classNames(
						'caption-3-normal text-center',
						isDarkVariant ? 'text-grayscale-100' : 'text-typography-secondary'
					)}
				>
					<FormattedMessage id="TransientFooter.FooterTextPoweredBy" defaultMessage="Powered By" />
				</p>
			</div>
			{hidePlugitLogo ? (
				<div className="flex justify-center">
					<VoltalityLogoIcon fill={isDarkVariant ? '#FFFFFF' : ''} className="mt-1 h-8" />
				</div>
			) : (
				<div className="flex items-center justify-center space-x-5">
					<div className="flex w-24 justify-end">
						<PlugitLogoImage variant={isDarkVariant ? 'white' : 'blue'} className="h-7" />
					</div>
					<div
						className={classNames(
							'mt-1 h-7 border-r border-opacity-30',
							isDarkVariant ? 'border-grayscale-100' : 'border-divider-primary'
						)}
					/>
					<div className="flex w-24 justify-start">
						<VoltalityLogoIcon fill={isDarkVariant ? '#FFFFFF' : ''} className="mt-1 h-8" />
					</div>
				</div>
			)}
		</div>
	)
}

const MemoisedTransientFooter = memo(TransientFooter)

export default MemoisedTransientFooter
