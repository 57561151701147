import { BRAND } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'
import {
	OmniSessionChargingPeriodDimensionType,
	OmniTariffPriceModifierType
} from 'src/_shared/enums/omni'
import {
	OmniSession,
	OmniSessionChargingPeriodDimension,
	OmniTariff,
	OmniTariffPriceModifier
} from 'src/_shared/types/omni'

/**
 * Safely formats the `tariff.grace_period_seconds` for display.
 * @param {number|undefined} value The grace period seconds, if any.
 * @returns {number} The formatted grace period seconds.
 */
export const formatGracePeriodSeconds = (value?: number): number => {
	if (value) {
		if (BRAND === Brand.SpGroup) {
			// SP begins counting from 1 instead of 0.
			return Math.max(0, value - 1)
		}
		return value
	}
	return 0
}

/**
 * Pads the time number value with a 0-prefix if required,
 * e.g. `9` -> `09` (padded), `11` -> `11` (unchanged)
 * @param value The time amount.
 * @returns {string} The padded time amount.
 */
export const formatPaddedTimeNumber = (value: number): string => {
	return value.toString().padStart(2, '0')
}

/**
 * Derives the actual hours, minutes, and seconds from the total number of seconds provided.
 * @param {number} value The amount in seconds.
 * @returns {object} `{ hours, minutes, seconds }`
 */
export const getTimeFromSeconds = (
	value: number
): { hours: number; minutes: number; seconds: number } => {
	let seconds = value

	let minutes = seconds >= 60 ? Math.floor(seconds / 60) : 0
	if (minutes > 0) {
		seconds %= 60
	}

	const hours = minutes >= 60 ? Math.floor(minutes / 60) : 0
	if (hours > 0) {
		minutes %= 60
	}

	return { hours, minutes, seconds }
}

/**
 * Formats a `time` object into a readable string.
 * @param {object} time `{ hours, minutes, seconds }`
 * @param {object} unitsFormat Singular and plural units to output for each time dimension.
 * @returns {string} Output Format: `<hours> hour(s) <minutes> minute(s) <seconds> second(s)`
 */
export const formatTimeToString = (
	time: {
		hours: number
		minutes: number
		seconds: number
	},
	unitsFormat: {
		hoursFormat?: [string, string]
		minutesFormat?: [string, string]
		secondsFormat?: [string, string]
	} = {}
): string => {
	const { hours, minutes, seconds } = time
	const {
		hoursFormat = ['h', 'h'],
		minutesFormat = ['min', 'min'],
		secondsFormat = ['s', 's']
	} = unitsFormat
	const timeDurationArr = [
		hours > 0 ? `${hours} ${hours > 1 ? hoursFormat[1] : hoursFormat[0]}` : null,
		minutes > 0 ? `${minutes} ${minutes > 1 ? minutesFormat[1] : minutesFormat[0]}` : null,
		seconds > 0 ? `${seconds} ${seconds > 1 ? secondsFormat[1] : secondsFormat[0]}` : null
	]
	return timeDurationArr.filter(Boolean).join(' ')
}

/**
 * Finds a specified Price Modifier inside of a Tariff.
 * @param {OmniTariff | null} tariff The Tariff to search in.
 * @param {OmniTariffPriceModifierType} priceModifierType The Price Modifier type to search for.
 * @returns {OmniTariffPriceModifier | null} If found, return the specified Price Modifier. Else, returns `null`.
 */
export const getTariffPriceModifier = (
	tariff: OmniTariff | null,
	priceModifierType: OmniTariffPriceModifierType
): OmniTariffPriceModifier | null => {
	const priceModifier = tariff?.price_modifiers?.find(
		(priceModifier): boolean => priceModifier.type === priceModifierType
	)
	return priceModifier ?? null
}

/**
 * Finds the State of Charge Charing Period Dimension inside of a Session's Charging Periods.
 * @param {OmniSession | null} session The Session to search in.
 * @returns {OmniSessionChargingPeriodDimension | null} If found, return the State of Charge Charing Period Dimension. Else, returns `null`.
 */
export const getSessionStateOfCharge = (
	session: OmniSession | null
): OmniSessionChargingPeriodDimension | null => {
	const isStateOfChargeDimension = (dimension: OmniSessionChargingPeriodDimension): boolean => {
		return dimension.type === OmniSessionChargingPeriodDimensionType.StateOfCharge
	}

	const stateOfChargeDimension = session?.charging_periods
		?.findLast((chargingPeriod): boolean => {
			return chargingPeriod.dimensions?.some(isStateOfChargeDimension) ?? false
		})
		?.dimensions?.find(isStateOfChargeDimension)

	if (stateOfChargeDimension) {
		return stateOfChargeDimension
	}
	return null
}
